import Head from 'next/head';

const fallbackTitle = 'Dynamic Flow Docs';

const fallbackDescription =
  "Welcome to the Wise Dynamic Flow documentation site. Here you'll find documentation, guides and tools to help you learn about Dynamic Flow, write your own, and integrate with existing ones.";

type Props = {
  title?: string;
  description?: string;
};

export const DFDocHead = (props: Props) => {
  const { title = fallbackTitle } = props;
  const { description = fallbackDescription } = props;
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta key="twitter:title" property="twitter:title" content={title} />
      <meta key="twitter:description" property="twitter:description" content={description} />
      <meta key="og:site_name" property="og:site_name" content="Wise Platform Docs" />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:url" property="og:url" content="https://docs.wise.com/dynamic-flow" />
      <meta key="og:description" property="og:description" content={description} />
    </Head>
  );
};
