const ENVIRONMENTS = {
  production: ['transferwise.com', 'wise.com'],
  staging: ['trwi.se', 'dev-wi.se'],
};

export const getEnvironmentFromURL = () => {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  const environment = Object.keys(ENVIRONMENTS).find((env) =>
    ENVIRONMENTS[env].some((domain) => hostname.indexOf(domain) !== -1),
  );
  return environment || '';
};
