import { NavigationItemOrGroup } from '../../types';

const legacySpecMenu: NavigationItemOrGroup[] = [
  {
    title: 'Overview',
    link: '/dynamic-flow/docs',
  },
  {
    title: 'Steps',
    subitems: [
      { title: 'Form Step', link: '/dynamic-flow/legacy-docs/spec/steps/form' },
      { title: 'Final Step (deprecated)', link: '/dynamic-flow/legacy-docs/spec/steps/final' },
      { title: 'Web Step (deprecated)', link: '/dynamic-flow/legacy-docs/spec/steps/web' },
      {
        title: 'External Step (deprecated)',
        link: '/dynamic-flow/legacy-docs/spec/steps/external',
      },
    ],
  },
  {
    title: 'Features',
    subitems: [
      { title: 'Actions', link: '/dynamic-flow/legacy-docs/spec/features/actions' },
      { title: 'Response Types', link: '/dynamic-flow/legacy-docs/spec/features/response-types' },
      {
        title: 'Refresh on Change',
        link: '/dynamic-flow/legacy-docs/spec/features/refresh-on-change',
      },
      { title: 'External', link: '/dynamic-flow/legacy-docs/spec/features/external' },
      { title: 'Polling', link: '/dynamic-flow/legacy-docs/spec/features/polling' },
      { title: 'Link Handling', link: '/dynamic-flow/legacy-docs/spec/features/link-handling' },
      { title: 'Persist Async', link: '/dynamic-flow/legacy-docs/spec/features/persist-async' },
      {
        title: 'Validation Asyc',
        link: '/dynamic-flow/legacy-docs/spec/features/validation-async',
      },
      { title: 'Error Handling', link: '/dynamic-flow/legacy-docs/spec/features/error-handling' },
      {
        title: 'Analytics Metadata',
        link: '/dynamic-flow/legacy-docs/spec/features/analytics-metadata',
      },
    ],
  },
  {
    title: 'Schemas',
    subitems: [
      { title: 'AllOf Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/allOf' },
      { title: 'Array Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/array' },
      { title: 'Blob Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/blob' },
      { title: 'Boolean Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/boolean' },
      { title: 'Integer Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/integer' },
      { title: 'Number Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/number' },
      { title: 'Object Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/object' },
      { title: 'OneOf Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/oneOf' },
      { title: 'String Schema', link: '/dynamic-flow/legacy-docs/spec/schemas/string' },
    ],
  },
  {
    title: 'Layouts',
    subitems: [
      { title: 'Alert Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/alert' },
      { title: 'Box Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/box' },
      { title: 'Button Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/button' },
      { title: 'Columns Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/columns' },
      { title: 'Decision Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/decision' },
      { title: 'Form Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/form' },
      { title: 'Heading Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/heading' },
      { title: 'Image Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/image' },
      { title: 'Info Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/info' },
      { title: 'List Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/list' },
      {
        title: 'Loading Indicator Layout',
        link: '/dynamic-flow/legacy-docs/spec/layouts/loading-indicator',
      },
      { title: 'Paragraph Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/paragraph' },
      { title: 'Review Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/review' },
      { title: 'Search Layout', link: '/dynamic-flow/legacy-docs/spec/layouts/search' },
    ],
  },
  {
    title: 'Miscelaneous',
    subitems: [
      { title: 'Align', link: '/dynamic-flow/legacy-docs/spec/misc/align' },
      { title: 'Icons', link: '/dynamic-flow/legacy-docs/spec/misc/icons' },
      { title: 'Images', link: '/dynamic-flow/legacy-docs/spec/misc/images' },
      { title: 'Size', link: '/dynamic-flow/legacy-docs/spec/misc/size' },
    ],
  },
];

const specMenu: NavigationItemOrGroup[] = [
  {
    title: 'Overview',
    link: '/dynamic-flow/docs',
  },
  {
    title: 'Steps',
    subitems: [{ title: 'Step', link: '/dynamic-flow/docs/spec/steps/step' }],
  },
  {
    title: 'Features',
    subitems: [
      { title: 'Actions', link: '/dynamic-flow/docs/spec/features/actions' },
      { title: 'Response Types', link: '/dynamic-flow/docs/spec/features/response-types' },
      { title: 'Refresh on Change', link: '/dynamic-flow/docs/spec/features/refresh-on-change' },
      { title: 'External', link: '/dynamic-flow/docs/spec/features/external' },
      { title: 'Polling', link: '/dynamic-flow/docs/spec/features/polling' },
      { title: 'Link Handling', link: '/dynamic-flow/docs/spec/features/link-handling' },
      { title: 'Persist Async', link: '/dynamic-flow/docs/spec/features/persist-async' },
      { title: 'Validation Asyc', link: '/dynamic-flow/docs/spec/features/validation-async' },
      { title: 'Error Handling', link: '/dynamic-flow/docs/spec/features/error-handling' },
      { title: 'Analytics Metadata', link: '/dynamic-flow/docs/spec/features/analytics-metadata' },
    ],
  },
  {
    title: 'Schemas',
    subitems: [
      { title: 'AllOf Schema', link: '/dynamic-flow/docs/spec/schemas/allOf' },
      { title: 'Array Schema', link: '/dynamic-flow/docs/spec/schemas/array' },
      { title: 'Blob Schema', link: '/dynamic-flow/docs/spec/schemas/blob' },
      { title: 'Boolean Schema', link: '/dynamic-flow/docs/spec/schemas/boolean' },
      { title: 'Integer Schema', link: '/dynamic-flow/docs/spec/schemas/integer' },
      { title: 'Number Schema', link: '/dynamic-flow/docs/spec/schemas/number' },
      { title: 'Object Schema', link: '/dynamic-flow/docs/spec/schemas/object' },
      { title: 'OneOf Schema', link: '/dynamic-flow/docs/spec/schemas/oneOf' },
      { title: 'String Schema', link: '/dynamic-flow/docs/spec/schemas/string' },
    ],
  },
  {
    title: 'Layouts',
    subitems: [
      { title: 'Alert Layout', link: '/dynamic-flow/docs/spec/layouts/alert' },
      { title: 'Box Layout', link: '/dynamic-flow/docs/spec/layouts/box' },
      { title: 'Button Layout', link: '/dynamic-flow/docs/spec/layouts/button' },
      { title: 'Columns Layout', link: '/dynamic-flow/docs/spec/layouts/columns' },
      { title: 'Decision Layout', link: '/dynamic-flow/docs/spec/layouts/decision' },
      { title: 'Form Layout', link: '/dynamic-flow/docs/spec/layouts/form' },
      { title: 'Heading Layout', link: '/dynamic-flow/docs/spec/layouts/heading' },
      { title: 'Image Layout', link: '/dynamic-flow/docs/spec/layouts/image' },
      { title: 'Instructions Layout', link: '/dynamic-flow/docs/spec/layouts/instructions' },
      { title: 'List Layout', link: '/dynamic-flow/docs/spec/layouts/list' },
      {
        title: 'Loading Indicator Layout',
        link: '/dynamic-flow/docs/spec/layouts/loading-indicator',
      },
      { title: 'Markdown Layout', link: '/dynamic-flow/docs/spec/layouts/markdown' },
      { title: 'Paragraph Layout', link: '/dynamic-flow/docs/spec/layouts/paragraph' },
      { title: 'Review Layout', link: '/dynamic-flow/docs/spec/layouts/review' },
      { title: 'Search Layout', link: '/dynamic-flow/docs/spec/layouts/search' },
    ],
  },
  {
    title: 'Miscelaneous',
    subitems: [
      { title: 'Align', link: '/dynamic-flow/docs/spec/misc/align' },
      { title: 'Icons', link: '/dynamic-flow/docs/spec/misc/icons' },
      { title: 'Images', link: '/dynamic-flow/docs/spec/misc/images' },
      { title: 'Size', link: '/dynamic-flow/docs/spec/misc/size' },
    ],
  },
];

const guidesMenu: NavigationItemOrGroup[] = [
  {
    title: 'Overview',
    link: '/dynamic-flow/guides',
  },
  {
    title: 'Integrating with an existing flow',
    link: '/dynamic-flow/guides/integrators',
    subitems: [
      { title: 'Integrating on Web', link: '/dynamic-flow/guides/integrators/web' },
      { title: 'Integrating on iOS', link: '/dynamic-flow/guides/integrators/ios' },
      { title: 'Integrating on Android', link: '/dynamic-flow/guides/integrators/android' },
    ],
  },
  {
    title: 'Writing or editing a flow',
    link: '/dynamic-flow/guides/creators',
  },
];

const featuresMenu: NavigationItemOrGroup[] = [
  {
    title: 'Overview',
    link: '/dynamic-flow/features#dynamic-flow-features',
  },
  {
    title: 'Content display',
    link: '/dynamic-flow/features#content-display',
    subitems: [
      { title: 'Heading', link: '/dynamic-flow/features#heading' },
      { title: 'Paragraph', link: '/dynamic-flow/features#paragraph' },
      { title: 'Markdown', link: '/dynamic-flow/features#markdown' },
      { title: 'StatusList', link: '/dynamic-flow/features#statuslist' },
      { title: 'Review', link: '/dynamic-flow/features#review' },
      { title: 'Image', link: '/dynamic-flow/features#image' },
      { title: 'Alert', link: '/dynamic-flow/features#alert' },
      { title: 'Instructions', link: '/dynamic-flow/features#instructions' },
    ],
  },
  {
    title: 'Form components',
    link: '/dynamic-flow/features#form-components',
    subitems: [
      { title: 'Text', link: '/dynamic-flow/features#text' },
      { title: 'Text area', link: '/dynamic-flow/features#text-area' },
      { title: 'Number', link: '/dynamic-flow/features#number' },
      { title: 'Integer', link: '/dynamic-flow/features#integer' },
      { title: 'Checkbox', link: '/dynamic-flow/features#checkbox' },
      { title: 'Date', link: '/dynamic-flow/features#date' },
      { title: 'Date lookup', link: '/dynamic-flow/features#date-lookup' },
      { title: 'Radio', link: '/dynamic-flow/features#radio' },
      { title: 'Select', link: '/dynamic-flow/features#select' },
      { title: 'Multi-select', link: '/dynamic-flow/features#multi-select' },
      { title: 'Password', link: '/dynamic-flow/features#password' },
      { title: 'Phone Number', link: '/dynamic-flow/features#phone-number' },
      { title: 'Tabs', link: '/dynamic-flow/features#tabs' },
      { title: 'Upload', link: '/dynamic-flow/features#upload' },
    ],
  },
  {
    title: 'Interactive components',
    link: '/dynamic-flow/features#interactive-components',
    subitems: [
      { title: 'Button', link: '/dynamic-flow/features#button' },
      { title: 'Copyable', link: '/dynamic-flow/features#copyable' },
      { title: 'Decision', link: '/dynamic-flow/features#decision' },
      { title: 'Modal', link: '/dynamic-flow/features#modal' },
    ],
  },
  {
    title: 'Layout components',
    link: '/dynamic-flow/features#layout-components',
    subitems: [
      { title: 'Box', link: '/dynamic-flow/features#box' },
      { title: 'Columns', link: '/dynamic-flow/features#columns' },
      { title: 'Loading indicator', link: '/dynamic-flow/features#loading-indicator' },
      { title: 'Async search', link: '/dynamic-flow/features#async-search' },
      { title: 'Align', link: '/dynamic-flow/features#align' },
      { title: 'Margin', link: '/dynamic-flow/features#margin' },
    ],
  },
  {
    title: 'Form features',
    link: '/dynamic-flow/features#form-features',
    subitems: [
      { title: 'Required', link: '/dynamic-flow/features#required' },
      { title: 'Disabled', link: '/dynamic-flow/features#disabled' },
      { title: 'Model', link: '/dynamic-flow/features#model' },
      { title: 'Placeholder', link: '/dynamic-flow/features#placeholder' },
      { title: 'Persisting data', link: '/dynamic-flow/features#persisting-data' },
      { title: 'Validation', link: '/dynamic-flow/features#validation' },
      { title: 'Validation pattern', link: '/dynamic-flow/features#validation-pattern' },
      { title: 'Validation messages', link: '/dynamic-flow/features#validation-messages' },
      { title: 'Server-side validation', link: '/dynamic-flow/features#server-side-validation' },
      { title: 'Repeating items', link: '/dynamic-flow/features#repeating-items' },
      { title: 'Sections', link: '/dynamic-flow/features#sections' },
    ],
  },
  {
    title: 'Step features',
    link: '/dynamic-flow/features#step-features',
    subitems: [
      { title: 'Camera', link: '/dynamic-flow/features#camera' },
      { title: 'External', link: '/dynamic-flow/features#external' },
      { title: 'Global error', link: '/dynamic-flow/features#global-error' },
      { title: 'Polling', link: '/dynamic-flow/features#polling' },
    ],
  },
  {
    title: 'Flow events',
    link: '/dynamic-flow/features/events',
  },
];

export { specMenu, legacySpecMenu, guidesMenu, featuresMenu };
