import '@transferwise/neptune-css/dist/css/neptune.css';
import '@transferwise/components/build/main.css';
import '@transferwise/icons/lib/styles/main.min.css';
import 'currency-flags/dist/currency-flags.min.css';

import '../styles/global.scss';
import '../styles/apiDocumentation.scss';
import '../styles/dynamicFlow.scss';

import { withCustomApp } from '@transferwise/crab/pages/_app';
import { NextComponentType } from 'next';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import ApiDocumentationApp from '../apps/apiDocumentation/ApiDocumentationApp';
import DynamicFlowApp from '../apps/dynamicFlow/DynamicFlowApp';
import { Provider } from '../components/Provider/Provider';
import { initRollbar } from '../helpers/rollbar';
import {
  initMixpanel,
  track,
  stopListeningToCookieAcceptanceForMixpanel,
} from '../helpers/tracking';

export type AppProps = {
  children: ReactNode;
};

const App = ({ children }: AppProps) => {
  const router = useRouter();
  const { pathname, asPath } = router;

  // The design system doesn't condone use of extra small buttons in product, but we still use them on our docs
  // site. If necessary, when they remove support we can create our own custom button. In the meantime... sorry.

  // eslint-disable-next-line no-console
  const originalWarn = console.warn;

  const fakeWarn: typeof originalWarn = (message: string) => {
    if (
      message !==
      'Button has deprecated the `Size.EXTRA_SMALL` value for the `size` prop. Please use Size.SMALL instead.'
    ) {
      originalWarn(message);
    }
  };

  // eslint-disable-next-line no-console, fp/no-mutation
  console.warn = fakeWarn;

  useEffect(() => {
    initMixpanel();
    void initRollbar();
    return () => {
      stopListeningToCookieAcceptanceForMixpanel();
    };
  }, []);

  useEffect(() => {
    track('Page View', { path: asPath });
  }, [asPath]);

  if (pathname?.startsWith('/public/dynamic-flow')) {
    return <DynamicFlowApp>{children}</DynamicFlowApp>;
  }

  return <ApiDocumentationApp>{children}</ApiDocumentationApp>;
};

const AppWithProvider = (props: AppProps) => (
  <Provider>
    <App {...props} />
  </Provider>
);

const CustomApp = withCustomApp({ AppTemplate: AppWithProvider as NextComponentType });

export default CustomApp;
