import { getEnvironmentFromURL } from '../getEnvironmentFromURL';

// We are using client side rollbar from crab, but there is an issue - it is not enabled
// out of the box for us. It gets enabled based on process.env.DEPLOYMENT value,
// which is defined in k8s manifests. We are using server side generation (SSG),
// and with that we are generating static html at build time, but at this
// stage DEPLOYMENT is not set. Build runs on circleCI and is not aware of the target environment.
export async function initRollbar() {
  try {
    const environment = getEnvironmentFromURL();
    window.Rollbar.configure({
      enabled: ['staging', 'production'].includes(environment),
      payload: { environment },
    });
  } catch (err) {
    // not the end of the world if rollbar fails to init
  }
}
