import { hasValidConsent, COOKIE_CONSENT_ACCEPT_EVENT_NAME } from '@transferwise/cookie-consent';
import mixpanel from 'mixpanel-browser';

import { getEnvironmentFromURL } from '../getEnvironmentFromURL';

const MIXPANEL_TOKEN = 'e605c449bdf99389fa3ba674d4f5d919';
const DEV_TOKEN = '1';

const handleCookieAcceptance = () => {
  mixpanel.opt_in_tracking();
};

// Based on https://github.com/transferwise/crab/blob/master/src/client/services/tracking/mixpanel.ts,
// but as we're generating static pages at build time we cannot rely on the same environment detection.
// Static pages are generated at build time. At that stage we cannot know what the env is (DEPLOYMENT var).
export function initMixpanel() {
  const environment = getEnvironmentFromURL();
  const token = environment === 'production' ? MIXPANEL_TOKEN : DEV_TOKEN;

  const shouldOptOut = !hasValidConsent();
  mixpanel.init(token, {
    opt_out_tracking_by_default: shouldOptOut,
    opt_out_persistence_by_default: shouldOptOut,
    disable_notifications: true,
    batch_flush_interval_ms: 800,
    ...(environment !== 'production' && { debug: true }),
  });

  /**
   * In cases where the user has already consented, i.e., in a non-eu location
   * it is possible that the event listener below will be added *after* the
   * event has already been dispatched.
   *
   * This ensures we opt into tracking at the earliest possible chance, to
   * allow consumers to use `mixpanel.has_opted_in_tracking()`.
   */
  if (hasValidConsent()) {
    mixpanel.opt_in_tracking();
  }

  if (shouldOptOut) {
    window.addEventListener(COOKIE_CONSENT_ACCEPT_EVENT_NAME, handleCookieAcceptance);
  }
}

export const stopListeningToCookieAcceptanceForMixpanel = (): void => {
  window.removeEventListener(COOKIE_CONSENT_ACCEPT_EVENT_NAME, handleCookieAcceptance);
};

export function track(eventName: string, params = {}) {
  mixpanel.track(`Docs - ${eventName}`, params);
}
