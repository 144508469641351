import {
  Provider as ComponentProvider,
  ProviderProps,
  SnackbarProvider,
  translations as componentTranslations,
} from '@transferwise/components';
import { ThemeProvider } from '@wise/components-theming';
import { translations as dfTranslations } from '@wise/dynamic-flow-client-internal';
import React from 'react';

const locale = 'en-GB';
const lang = 'en';

const messages: Record<string, string> = {
  ...componentTranslations[lang],
  ...dfTranslations[lang],
};

const i18n: ProviderProps['i18n'] = { locale, messages };

export const Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme="personal" screenMode="light">
      <SnackbarProvider>
        <ComponentProvider i18n={i18n}>{children}</ComponentProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
